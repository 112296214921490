body {
	font-family: "Rubik";
	margin: 0px;
	width: 100%;
    display: block;
	position: absolute; 
	bottom: 0;
	z-index: 1000;
	height: 100%;
	/* background-color: var(--beige-beige-02); */
}

#root {
	height: 100%;
}

.property-title-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.property-title-container h1 {
	margin: 0;
	text-align: center;
}

@font-face {
	font-family: "Rubik";
	font-style: normal;
	font-weight: 1 999;
	src: url('../assets/rubik.ttf');
}

@font-face {
	font-family: "Rubik";
	font-style: italic;
	font-weight: 1 999;
	src: url('../assets/rubik-italic.ttf');
}

.no-scroll {
	overflow: hidden;
}